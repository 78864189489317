import React from 'react'
import Divider from 'components/divider'

export default () => (
  <section className="o-container mt-20 mb-40">

    <Divider color="terracotta" />
    <h2 className="mt-20 mb-40 h2">Helios portfolio</h2>

    <div className="o-table__title">
      <span className="u-bold">
        Top ten holdings by Managing Agent
      </span> (Sep 2024)
    </div>

    <div className="o-table__container">

      <table className="o-table o-table--llrr">
        <thead>
          <tr>
            <th width="10%">Syndicate</th>
            <th>Managing agent</th>
            <th width="10%">Capacity £M</th>
            <th width="10%">Helios portfolio % of total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>623</td>
            <td>Beazley</td>
            <td>32.7</td>
            <td>6.53</td>
          </tr>
          <tr>
            <td>5886</td>
            <td>Blenheim</td>
            <td>30.8</td>
            <td>6.15</td>
          </tr>
          <tr>
            <td>510</td>
            <td>TMK</td>
            <td>30.3</td>
            <td>6.05</td>
          </tr>
          <tr>
            <td>5623</td>
            <td>Beazley</td>
            <td>27</td>
            <td>5.39</td>
          </tr>
          <tr>
            <td>1969</td>
            <td>Apollo</td>
            <td>25.5</td>
            <td>5.09</td>
          </tr>
          <tr>
            <td>1729</td>
            <td>Dale</td>
            <td>25.1</td>
            <td>5.01</td>
          </tr>
          <tr>
            <td>1971</td>
            <td>Apollo-Ibott</td>
            <td>25</td>
            <td>4.99</td>
          </tr>
          <tr>
            <td>1985</td>
            <td>Flux</td>
            <td>20</td>
            <td>3.99</td>
          </tr>
          <tr>
            <td>2358</td>
            <td>Nephila</td>
            <td>20</td>
            <td>3.99</td>
          </tr>
          <tr>
            <td>1955</td>
            <td>Arch</td>
            <td>20</td>
            <td>3.99</td>
          </tr>
          

        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2">Subtotal</td>
            <td>256.4</td>
            <td>50.1</td>
          </tr>
          <tr>
            <td colSpan="2">Other</td>
            <td>255.6</td>
            <td>46.9</td>
          </tr>
          <tr>
            <td colSpan="2">Total Helios portfolio</td>
            <td>512</td>
            <td>100</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
)